.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0052ff;
    padding: 2rem 2rem;
    height: 80px;
    border-bottom: 2px solid white;
  }
  
  .header-logo img {
    height: 100px;
  }
  
  .header-nav,
  .header-social {
    display: flex;
    align-items: center;
  }
  
  .header-nav {
    margin-right: auto; 
  }

 a {
cursor: pointer;
  }
  
  .header-social {
    margin-left: auto; 
  }
  
  .nav-link {
    color: #C9D1D9;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
  
  .header-social a img {
    height: 30px;
    margin: 0 0.5rem;
  }
  
  .header-wallet-btn {
    padding: 10px 20px;
    background-color: #2081e2;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 600;
  }
  


  .header-nav a{
    margin: 0 1.5rem; 
  }
  
  .header-social a {
    margin: 0 0.5rem; 
  }
  
  .header-wallet-btn {
    margin-left: 1rem; 
  }
  

  @media (max-width: 768px) {
    .header-nav {
      display: none;
    }
  }
  