.mechanism {
    color: white;
    text-align: center;
    padding: 100px 15%; 
  }
  
  .mechanism h2 {
    margin-bottom: 60px;
    font-size: 3rem;
  }
  
  .mechanism-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }

  .mechanism-item {
    border-radius: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center; 
    box-sizing: border-box;
  }
  
  .mechanism-item img {
    width: 254px;
    height: 254px;
    margin-bottom: 15px;
    align-self: center; 
  }
  
  .mechanism-item p {
    font-size: 2.5rem;
    color: #C9D1D9;
    text-align: center;
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    .mechanism-item {
      width: 100%; 
    }
  }
  
  @media (max-width: 480px) {
    .mechanism-item {
      width: 100%; 
    }
  }
  


  @keyframes vibration {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px); }
    50% { transform: translateX(2px); }
    75% { transform: translateX(-2px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: vibration 0.3s infinite;
  }


  @keyframes vibrationZoom {
    0% { 
      transform: translateX(0) scale(1); 
    }
    25% { 
      transform: translateX(-2px) scale(1.1); 
    }
    50% { 
      transform: translateX(2px) scale(0.9); 
    }
    75% { 
      transform: translateX(-2px) scale(1.1); 
    }
    100% { 
      transform: translateX(0) scale(1); 
    }
  }
  
  .shakeZoom {
    animation: vibrationZoom 2s infinite;
  }
  