

.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 50px 20px 50px 20px;
}

.banner-image-container {
  flex: 1;
}

.banner-image {
  max-width: 60%;
  height: auto;
}

.text-button-container {
  flex: 2;
  margin-left: 20px;
}

.text-button-container h1 {
  margin-bottom: 2rem; 
  font-size: 3.3rem; 
}

p{
  font-size: 2.1rem;
}

.enter-dapp-btn {
  font-family: "Kranky", serif;
  padding: 1rem 2rem;
  background-color: #004887;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;

}

.enter-dapp-btn:hover {
  background-color: #1058a2; 
}

@media (max-width: 768px) {
  .landing-container {
    flex-direction: column;
  }

  .text-button-container {
    margin-left: 0;
    margin-top: 20px;
  }
}
