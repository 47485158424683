.footer {
  border-top: 2px solid white;
    color: white;
    text-align: center;
    padding: 1.5rem 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer p {
    margin: 0; 
    font-size: 2rem;
  }
  
  .footer-icons a img {
    height: 35px;
    margin: 0 5px; 
  }
  